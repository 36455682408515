<template>
    <div>
    <v-toolbar elevation="0">
        <v-toolbar-title>Reports</v-toolbar-title>
    </v-toolbar>
<v-expansion-panels>
    <v-expansion-panel
      v-for="(item,i) in reports"
      :key="i"
    >
      <v-expansion-panel-header>
        {{item.title}}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        {{item.description}}
        <v-form ref="form">
            <v-select
            label="Select Group"
            :items="group.items"
            v-model="group"></v-select>
            <v-text-field
            label="Start Date"
            type="date"
            v-model="startDate"></v-text-field>
            <v-text-field
            label="End Date"
            type="date"
            v-model="endDate"></v-text-field>
            <v-btn color="primary" :click="item.action" :loading="loading">Generate Report</v-btn>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
    </div>
</template>
<style scoped>
.add {
    bottom: 15px !important;
}
</style>
<script>
  export default {
    data: () => ({
        reports: [
            { title: "Attendance", description: "Report to check student attendance.", action: "reportAttendance" },
            { title: "Scores", description: "Report to check student scores.", action: "reportScores" },
            { title: "Surveys", description: "Report to check student surveys.", action: "reportSurveys" },
        ],
        loading: false,
        groups: [],
        group: '',
        startDate: '',
        endDate: '',
    }),
    methods: {
        reportAttendance: function(){},
        reportScores: function(){},
        reportSurveys: function(){},
    },
    mounted: function(){
    }
  }
</script>
